import React from "react"
import {
  FaCode,
  FaSketch,
  FaShoppingCart,
  FaMailBulk,
  FaWordpressSimple,
  FaServer,
} from "react-icons/fa"
const services = [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "web development",
    text: `Background in html/css/JavaScript, Content Management Systems, API and database technologies, concentrating on your business logic and objectives.`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "web design",
    text: `Developing websites to express your brand or services, whether it's brick-and-mortar, or completely online. We use mobile-first development strategies and focus on SEO, towards your success.`,
  },
  {
    id: 3,
    icon: <FaShoppingCart className="service-icon" />,
    title: "e-commerce development",
    text: `Shopify and Woocommerce development and integrations with your preferred framework, enhancing and integrating your sales systems.`,
  },
  {
    id: 4,
    icon: <FaMailBulk className="service-icon" />,
    title: "Mailing list and CRM setup/management",
    text: `Infusionsoft/Keap is my specialty, with integrations, onboarding, campaign development and more.  I can design and consult on various CRM and marketing automations.`,
  },
  {
    id: 5,
    icon: <FaWordpressSimple className="service-icon" />,
    title: "Content Management Dev and Admin",
    text: `From simple to complex, "Headless" React and Wordpress development, integrations, Woocommerce, membership sites, theme and plugin dev.`,
  },
  {
    id: 5,
    icon: <FaServer className="service-icon" />,
    title: "Hosting/Provisioning/Sysadmin",
    text: `Virtual private servers, exclusive shared hosting, domain registration, network administration and IT management for your small business.`,
  },
]

export default services
