const customers = [
  {
    id: 1,
    image:
      "https://infoarc-assets.s3.amazonaws.com/images/barrygreen-831x1030.jpeg",
    name: "Barry Green",
    title: "Bassist, author, educator; Inner Game of Music",
    quote:
      "I've been working with Trey for over 15 years. He has masterfully designed many websites for me including Golden Gate Bass Camps (every year), Inner Game of Music, Mastery of Music, Green Arts Network, San Diego Bass Explosion and several more on the horizon. His work is of the highest quality including the most recent trends and tools in print, design, media, sound, video, graphics, etc. Trey's customer service is tops. He makes me feel like I'm the only person he is working for.",
  },
  {
    id: 2,
    image:
      "https://infoarc-assets.s3.amazonaws.com/images/betsymcnair-240x287.jpeg",
    name: "Betsy McNair",
    title: "Culinary Tour Entrepreneur; My Mexico Tours",
    quote:
      "Trey, the new and improved My Mexico Tours site is awesome. It's clean, bold, and superbly user friendly, exactly what I wanted. And I really enjoyed the process of getting it there, you were great to work with - responsive, on time, and clear.",
  },
  {
    id: 3,
    image:
      "https://infoarc-assets.s3.amazonaws.com/images/reneerestivo-400x515.jpeg",
    name: "Renée Restivo",
    title: "Writer & Cooking Instructor; Soul of Sicily",
    quote:
      "Trey Donovan made my dream for a Soul of Sicily cooking school website a reality. His enthusiasm, passion and attention to detail helped to make the project a success. His work combines the structural with the creative, and the results he produced exceeded my expectations. When I needed the Soul of Sicily website in time for my presentation at The New York Times Travel Show in NYC, Trey produced it on deadline so that my audience could view my website on their iPhones.",
  },
  {
    id: 4,
    image:
      "https://infoarc-assets.s3.amazonaws.com/images/leehartley-234x259.png",
    name: "Lee Hartley, Ed.D. MFT",
    title: "Psychotherapist & Educator",
    quote:
      "Trey's website designs are awesome! He asks pertinent questions, listens and gives many good suggestions. He was so helpful in keeping me focused--not only on the process, but also on completion within a short time frame. Trey makes setting up and maintaining an attractive, innovative website a smooth process. I'm impressed!",
  },
]

export default customers
