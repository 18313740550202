import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FaGithubSquare, FaShareSquare } from "react-icons/fa"
import { Link } from "gatsby"

const Project = ({
  body,
  title,
  github,
  stack,
  url,
  mainImage,
  index,
  slug,
}) => {
  console.log()
  return (
    <article className="project">
      <GatsbyImage
        image={getImage(mainImage.asset)}
        className="project-img"
        alt={title}
      />
      <div className="project-info">
        <span className="project-number">0{index + 1}.</span>
        <Link to={`/projects/${slug.current}`} className="project-slug">
          <h3>{title}</h3>
        </Link>

        <p className="project-desc">
          {body.map(item => {
            return (
              <span key={item._key}>
                {item.children.map(item2 => {
                  return <span key={item2._key}>{item2.text}</span>
                })}
              </span>
            )
          })}
        </p>
        <div className="project-stack">
          {stack.map(item => {
            return <span>{item}</span>
          })}
        </div>
        <div className="project-links">
          <a href={github}>
            <FaGithubSquare className="project-icon">></FaGithubSquare>
          </a>
          <a href={url}>
            <FaShareSquare className="project-icon">></FaShareSquare>
          </a>
        </div>
      </div>
    </article>
  )
}

export default Project
