import React, { useState, useEffect } from "react"
// import Title from "./Title"
import styled from "styled-components"
import data from "../constants/customerdata"
// import { FaQuoteRight } from "react-icons/fa"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FiChevronRight, FiChevronLeft } from "react-icons/fi"

function Slider() {
  const [customers, setCustomers] = useState(data)
  const [index, setIndex] = React.useState(0)

  useEffect(() => {
    const lastIndex = customers.length - 1
    if (index < 0) {
      setIndex(lastIndex)
    }
    if (index > lastIndex) {
      setIndex(0)
    }
  }, [index, customers])

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1)
    }, 15000)
    return () => {
      clearInterval(slider)
    }
  }, [index])

  return (
    <Wrapper className="section">
      <div className="section-title">
        <h2>reviews</h2>
      </div>
      <div className="section-center">
        {customers.map((person, personIndex) => {
          const { id, image, name, title, quote } = person

          let position = "nextSlide"
          if (personIndex === index) {
            position = "activeSlide"
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === customers.length - 1)
          ) {
            position = "lastSlide"
          }

          return (
            <article className={position} key={id}>
              <img src={image} alt={name} className="person-img" />
              <h4>{name}</h4>
              <p className="title">{title}</p>
              <p className="text">{quote}</p>
              {/* <FaQuoteRight className="icon" /> */}
            </article>
          )
        })}
        <button className="prev" onClick={() => setIndex(index - 1)}>
          <FiChevronLeft />
        </button>
        <button className="next" onClick={() => setIndex(index + 1)}>
          <FiChevronRight />
        </button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: var(--clr-grey-10);
  .section-center {
    margin-top: 4rem;
    width: 80vw;
    height: 450px;
    max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
    /* background: red; */
    .person-img {
      border-radius: 50%;
      margin-bottom: 1rem;
      width: 150px;
      height: 150px;
      object-fit: cover;
      border: 4px solid var(--clr-grey-8);
      box-shadow: var(--dark-shadow);
    }
    h4 {
      text-transform: uppercase;
      color: var(--clr-primary-5);
      margin-bottom: 0.25rem;
    }
    .section-title h2 {
      text-transform: capitalize;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 0.75rem;
    }
    .text {
      max-width: 40em;
      margin: 0 auto;
      line-height: 2;
      color: var(--clr-grey-5);
    }
    .icon {
      font-size: 3rem;
      margin-top: 1.5rem;
      color: var(--clr-primary-5);
    }
    .prev,
    .next {
      position: absolute;
      top: 200px;
      transform: translateY(-50%);
      background: var(--clr-grey-5);
      color: var(--clr-white);
      width: 1.25rem;
      height: 1.25rem;
      display: grid;
      place-items: center;
      border-color: transparent;
      font-size: 1rem;
      border-radius: var(--radius);
      cursor: pointer;
      transition: var(--transition);
    }
    .prev:hover,
    .next:hover {
      background: var(--clr-primary-5);
    }
    .prev {
      left: 0;
    }
    .next {
      right: 0;
    }
    @media (min-width: 800px) {
      .prev,
      .next {
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
      }
    }
    article {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: var(--transition);
    }
    article.activeSlide {
      opacity: 1;
      transform: translateX(0);
    }
    article.lastSlide {
      transform: translateX(-100%);
    }
    article.nextSlide {
      transform: translateX(100%);
    }
  }
`
export default Slider
