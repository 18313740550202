import React from "react"
import { Link } from "gatsby"
import socialLinks from "../constants/social_links"
// import heroImg from "../assets/images/hero.svg"
// import { StaticImage } from "gatsby-plugin-image"
import WistiaEmbed from "./WistiaEmbed"

const Hero = () => {
  return (
    <header className="hero">
      <section className="section-center hero-center">
        <article className="hero-info">
          <div>
            <div className="underline"></div>
            <h1>InfoArc</h1>
            <h4>
              Trey Donovan Drake:
              <br />
              Web and e-commerce development
            </h4>
            <p>
              <strong>
                <u>Information Architecture:</u>
              </strong>
              <br />
              The practice of organizing the information, content,
              <br />
              and functionality of a web site so that it presents the best user
              <br />
              experience it can, with information and services being easily
              <br />
              usable and findable
            </p>
            <Link to="/contact" className="btn">
              Contact
            </Link>
            <div class="social-links">
              {socialLinks.map(link => {
                return (
                  <a className="social-link" href={link.url} key={link.id}>
                    {link.icon}
                  </a>
                )
              })}
            </div>
          </div>
        </article>
        {/* <StaticImage
          src="../assets/images/hero.svg"
          alt="Portfolio"
          className="hero-img"
          placeholder="blurred"
        /> */}
        <article class="wistia-hero">
          <WistiaEmbed hashedId="1s0l8b9v9a" />
        </article>
      </section>
    </header>
  )
}

export default Hero
